.header {
  /* background-color: rgba(255, 255, 255, 0.90); */
  background-color: rgb(217, 226, 236);
  font-family: "Fira Sans", sans-serif !important;
}

.header-logo {
  color: #4471b9;
  font-family: "Fira Sans" !important;
}

.logo {
  font-weight: bold !important;
}

.header .is-info {
  color: #4471b9 !important;
}
