.Search {
  margin: 10px 0 40px 0;
}

.Search__input {
  width: 100%;
  font-size: 24px;
  padding: 18px 24px;
  outline: none;
  border-radius: 12px;
  box-shadow: none;
  border: none;
  background-color: hsl(210, 36%, 92%);
  color: black;
  margin-bottom: 12px;
  margin-top: 12px;
}

.Search__input::placeholder {
  /* color: rgba(150, 150, 150, 1); */
  color: rgba(0, 0, 0, 0.5);
}

.Search__results {
  margin-top: -10px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 4px;
}

.Search__results-hidden {
  display: none;
}

.Search__result {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.75);
  margin: 16px 6px;
  display: block;
  padding: 12px 12px;
  border-radius: 6px;
  animation: result_in 400ms;
}

.Search__result:hover {
  background-color: hsl(210, 36%, 96%);
}

.Search__result-close-match {
  color: rgba(100, 150, 255, 1);
}

.Search__result-close-match .icon {
  color: rgba(100, 150, 255, 1);
}

@keyframes result_in {
  0% {
    transform: translateX(-20px);
    opacity: 0.5;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .Search__input {
    max-width: 95vw;
  }
}
