html {
  background-color: #eeeeee;
  min-height: 100vh;
}

body {
  margin: 0;
  padding: 62px 0 0 0 !important;
  font-family: "Open Sans", -apple-system, system-ui, BlinkMacSystemFont,
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f4f8;
  min-height: 100vh;
}

input {
  font-family: "Fira Sans", -apple-system, system-ui, BlinkMacSystemFont,
    sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold !important;
  font-family: "Fira Sans", -apple-system, system-ui, BlinkMacSystemFont,
    sans-serif !important;
}

p {
  font-size: 16px;
  font-family: "Open Sans", -apple-system, system-ui, BlinkMacSystemFont,
    sans-serif !important;
}

a {
  font-family: "Open Sans", -apple-system, system-ui, BlinkMacSystemFont,
    sans-serif !important;
}

.slight-margin__left {
  margin-left: 5px;
}

.slight-margin__right {
  margin-right: 5px;
}

.slight-margin__top {
  margin-top: 5px;
}

.slight-margin__bottom {
  margin-bottom: 5px;
}

.proper-margin__left {
  margin-left: 10px;
}

.proper-margin__right {
  margin-right: 10px;
}

.proper-margin__top {
  margin-top: 10px;
}

.proper-margin__bottom {
  margin-bottom: 10px;
}

.is-underlined {
  text-decoration: underline;
}

.is-underlined-red {
  color: red !important;
}

.header {
  /* background-color: rgba(255, 255, 255, 0.90); */
  background-color: rgb(217, 226, 236);
  font-family: "Fira Sans", sans-serif !important;
}

.header-logo {
  color: #4471b9;
  font-family: "Fira Sans" !important;
}

.logo {
  font-weight: bold !important;
}

.header .is-info {
  color: #4471b9 !important;
}

.PageWrapper {
  background-color: white;
  padding: 75px;
  margin-top: 30px;
  width: 950px !important;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .PageWrapper {
    padding: 10px;
    max-width: 100vw;
  }
}

.QuestionAndAnswer {
    margin-top: 15px;
    -webkit-animation: QA-fade-in 400ms;
            animation: QA-fade-in 400ms;
}

@-webkit-keyframes QA-fade-in {
    0%{opacity: 0}
    100%{opacity: 1}
}

@keyframes QA-fade-in {
    0%{opacity: 0}
    100%{opacity: 1}
}

.QuestionAndAnswer__answer {
    line-height: 2.5em;
}

.QuestionAndAnswer__answer-step-no {

}

.QuestionAndAnswer__related {
    margin-bottom: 1Lpx;
}
.QAList_item {
  margin: 5px auto;
  margin-bottom: 14px;
  font-size: 20px;
}

.QAList_item:first-of-type {
  margin-top: 24px;
}

.OverviewPage {
  width: 750px;
}

/* TOPICS */
.topics {
}

.topics__list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-top: 24px;
}

.topics__list__item {
  width: 32%;
  display: block;
  padding: 12px 18px;
  margin-bottom: 18px;
  background-color: #f0f4f8;
  margin-right: 2%;
  border-radius: 6px;
  font-size: 22px;
  font-weight: bold;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  -webkit-transition: box-shadow 400ms, color 400ms;
  transition: box-shadow 400ms, color 400ms;
}

.topics__list__item:hover {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.topics__list__item:nth-child(3) {
  margin-right: 0 !important;
}

.topics__list__item:nth-child(6) {
  margin-right: 0 !important;
}

.topics__list__item .arrow-icon {
  opacity: 0;
  -webkit-transition: opacity 400ms;
  transition: opacity 400ms;
}

.topics__list__item:hover .arrow-icon {
  opacity: 1;
}

/* GUIDES */

.guides__item__list {
  margin-top: 24px;
}

.guides__item {
  display: block;
  padding: 12px 18px;
  margin-bottom: 18px;
  background-color: #f0f4f8;
  margin-right: 2%;
  border-radius: 6px;
  font-size: 22px;
  font-weight: bold;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  -webkit-transition: box-shadow 400ms, color 400ms;
  transition: box-shadow 400ms, color 400ms;

  display: -webkit-flex;

  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}

.guides__item p {
  display: inline;
  font-size: 18px;
  margin-left: 18px;
}

.guides__item img {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  width: 240px;
  height: 80px;
  object-fit: cover;
  object-position: center;
  margin: -12px 0 -12px -18px;
  display: inline-block;
  border-right: 2px solid hsl(210, 36%, 92%);
  opacity: 0.6;
  -webkit-filter: grayscale(0.1) contrast(0.9);
          filter: grayscale(0.1) contrast(0.9);
  -webkit-transition: opacity 400ms, -webkit-filter 400ms;
  transition: opacity 400ms, -webkit-filter 400ms;
  transition: filter 400ms, opacity 400ms;
  transition: filter 400ms, opacity 400ms, -webkit-filter 400ms;
}

.guides__item:hover img {
  -webkit-filter: grayscale(0) contrast(1);
          filter: grayscale(0) contrast(1);
  opacity: 0.8;
}

.Search {
  margin: 10px 0 40px 0;
}

.Search__input {
  width: 100%;
  font-size: 24px;
  padding: 18px 24px;
  outline: none;
  border-radius: 12px;
  box-shadow: none;
  border: none;
  background-color: hsl(210, 36%, 92%);
  color: black;
  margin-bottom: 12px;
  margin-top: 12px;
}

.Search__input::-webkit-input-placeholder {
  /* color: rgba(150, 150, 150, 1); */
  color: rgba(0, 0, 0, 0.5);
}

.Search__input::-ms-input-placeholder {
  /* color: rgba(150, 150, 150, 1); */
  color: rgba(0, 0, 0, 0.5);
}

.Search__input::placeholder {
  /* color: rgba(150, 150, 150, 1); */
  color: rgba(0, 0, 0, 0.5);
}

.Search__results {
  margin-top: -10px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 4px;
}

.Search__results-hidden {
  display: none;
}

.Search__result {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.75);
  margin: 16px 6px;
  display: block;
  padding: 12px 12px;
  border-radius: 6px;
  -webkit-animation: result_in 400ms;
          animation: result_in 400ms;
}

.Search__result:hover {
  background-color: hsl(210, 36%, 96%);
}

.Search__result-close-match {
  color: rgba(100, 150, 255, 1);
}

.Search__result-close-match .icon {
  color: rgba(100, 150, 255, 1);
}

@-webkit-keyframes result_in {
  0% {
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes result_in {
  0% {
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    opacity: 1;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .Search__input {
    max-width: 95vw;
  }
}

.Guide {
  max-width: 850px;
  padding: 5px 60px 100px 60px;
  margin: 30px auto;
  background-color: white;
}

.Guide h1,
.Guide h2 {
  margin: 30px auto 10px auto;
  font-weight: bold !important;
}

.Guide p {
  margin: 18px auto;
  text-align: justify;
  font-size: 22px;
  line-height: 38px;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}

.Guide blockquote {
  box-sizing: border-box;
  /* border-left: 4px solid rgb(68, 113, 185); */
  border-left: 4px solid rgb(51, 78, 104);
  /* background-color: rgba(68, 113, 185, 0.2); */
  background-color: rgba(179, 236, 255, 0.25098);
  padding: 16px 16px;
  margin: 36px auto;
  font-size: 18px;
}

.Guide_back {
  margin-top: 50px;
}

.Guide__paragraph b {
  font-size: 0.8em;
  border-bottom: 2px solid rgba(68, 113, 185, 0.1);
  padding-left: 4px;
  display: block;
  margin-top: 35px;
}

