.PageWrapper {
  background-color: white;
  padding: 75px;
  margin-top: 30px;
  width: 950px !important;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .PageWrapper {
    padding: 10px;
    max-width: 100vw;
  }
}
