.Guide {
  max-width: 850px;
  padding: 5px 60px 100px 60px;
  margin: 30px auto;
  background-color: white;
}

.Guide h1,
.Guide h2 {
  margin: 30px auto 10px auto;
  font-weight: bold !important;
}

.Guide p {
  margin: 18px auto;
  text-align: justify;
  font-size: 22px;
  line-height: 38px;
  hyphens: auto;
}

.Guide blockquote {
  box-sizing: border-box;
  /* border-left: 4px solid rgb(68, 113, 185); */
  border-left: 4px solid rgb(51, 78, 104);
  /* background-color: rgba(68, 113, 185, 0.2); */
  background-color: rgba(179, 236, 255, 0.25098);
  padding: 16px 16px;
  margin: 36px auto;
  font-size: 18px;
}

.Guide_back {
  margin-top: 50px;
}

.Guide__paragraph b {
  font-size: 0.8em;
  border-bottom: 2px solid rgba(68, 113, 185, 0.1);
  padding-left: 4px;
  display: block;
  margin-top: 35px;
}
