.OverviewPage {
  width: 750px;
}

/* TOPICS */
.topics {
}

.topics__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 24px;
}

.topics__list__item {
  width: 32%;
  display: block;
  padding: 12px 18px;
  margin-bottom: 18px;
  background-color: #f0f4f8;
  margin-right: 2%;
  border-radius: 6px;
  font-size: 22px;
  font-weight: bold;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  transition: box-shadow 400ms, color 400ms;
}

.topics__list__item:hover {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.topics__list__item:nth-child(3) {
  margin-right: 0 !important;
}

.topics__list__item:nth-child(6) {
  margin-right: 0 !important;
}

.topics__list__item .arrow-icon {
  opacity: 0;
  transition: opacity 400ms;
}

.topics__list__item:hover .arrow-icon {
  opacity: 1;
}

/* GUIDES */

.guides__item__list {
  margin-top: 24px;
}

.guides__item {
  display: block;
  padding: 12px 18px;
  margin-bottom: 18px;
  background-color: #f0f4f8;
  margin-right: 2%;
  border-radius: 6px;
  font-size: 22px;
  font-weight: bold;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  transition: box-shadow 400ms, color 400ms;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.guides__item p {
  display: inline;
  font-size: 18px;
  margin-left: 18px;
}

.guides__item img {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  width: 240px;
  height: 80px;
  object-fit: cover;
  object-position: center;
  margin: -12px 0 -12px -18px;
  display: inline-block;
  border-right: 2px solid hsl(210, 36%, 92%);
  opacity: 0.6;
  filter: grayscale(0.1) contrast(0.9);
  transition: filter 400ms, opacity 400ms;
}

.guides__item:hover img {
  filter: grayscale(0) contrast(1);
  opacity: 0.8;
}
