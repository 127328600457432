html {
  background-color: #eeeeee;
  min-height: 100vh;
}

body {
  margin: 0;
  padding: 62px 0 0 0 !important;
  font-family: "Open Sans", -apple-system, system-ui, BlinkMacSystemFont,
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f4f8;
  min-height: 100vh;
}

input {
  font-family: "Fira Sans", -apple-system, system-ui, BlinkMacSystemFont,
    sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold !important;
  font-family: "Fira Sans", -apple-system, system-ui, BlinkMacSystemFont,
    sans-serif !important;
}

p {
  font-size: 16px;
  font-family: "Open Sans", -apple-system, system-ui, BlinkMacSystemFont,
    sans-serif !important;
}

a {
  font-family: "Open Sans", -apple-system, system-ui, BlinkMacSystemFont,
    sans-serif !important;
}

.slight-margin__left {
  margin-left: 5px;
}

.slight-margin__right {
  margin-right: 5px;
}

.slight-margin__top {
  margin-top: 5px;
}

.slight-margin__bottom {
  margin-bottom: 5px;
}

.proper-margin__left {
  margin-left: 10px;
}

.proper-margin__right {
  margin-right: 10px;
}

.proper-margin__top {
  margin-top: 10px;
}

.proper-margin__bottom {
  margin-bottom: 10px;
}

.is-underlined {
  text-decoration: underline;
}

.is-underlined-red {
  color: red !important;
}
