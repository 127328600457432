.QuestionAndAnswer {
    margin-top: 15px;
    animation: QA-fade-in 400ms;
}

@keyframes QA-fade-in {
    0%{opacity: 0}
    100%{opacity: 1}
}

.QuestionAndAnswer__answer {
    line-height: 2.5em;
}

.QuestionAndAnswer__answer-step-no {

}

.QuestionAndAnswer__related {
    margin-bottom: 1Lpx;
}